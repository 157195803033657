@import '../../../../scss/theme-bootstrap';

.wishlist-product-row {
  display: flex;
  border-bottom: 1px solid $color-cream-stroke;
  margin-bottom: 24px;
  .unique-product & {
    margin: 0;
    border: none;
  }
  &__thumb-img {
    width: 85px;
    height: 85px;
    display: inline-block;
    img {
      width: inherit;
      height: inherit;
      min-width: 85px;
    }
  }
  &__add-to-bag {
    float: $rdirection;
    padding: 24px 0;
  }
  &__info {
    padding-#{$ldirection}: 32px;
    display: inline-block;
    width: 220px;
    font-size: 14px;
    &-details-size,
    &-details-qty {
      display: inline-block;
    }
    button {
      color: $color-black;
      text-decoration: none;
      &.wishlist-product-row__add-to-bag {
        background-color: inherit;
        border-style: inherit;
        text-decoration: underline;
        line-height: get-line-height(12px, 12px);
        color: $color-black;
      }
      &:focus-visible {
        outline-offset: 1px;
      }
    }
    button {
      &.wishlist-product-row__cta {
        background-color: inherit;
        border-style: inherit;
        color: $color-black;
        cursor: text;
        float: $rdirection;
        line-height: get-line-height(12px, 12px);
        padding: 24px 0;
      }
    }
  }
}
